import * as React from 'react'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import * as styles from '../../styles/workshop.module.css'

const Mbira = () => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      isHome={false}
      title="ジンバブエ楽器「ンビラ」ワークショップ"
      description="Mondでは、鉄の棒と木の板から作られたジンバブエの伝統楽器「ンビラ」の弾き方を学ぶワークショップを毎月開催しています。"
      image="https://www.mond.nagoya/static/0f4d7d46f96a713c27321fa5001bd271/d924c/thumbnail-mbira.jpg"
      article={true}
    >
      <div className={styles.workshopContainer}>
        <h1 className={styles.workshopTitle}>Workshop</h1>
        <div className={styles.workshopGrid}>
          <div className={styles.workshopBody}>
            {breakpoints.xs || breakpoints.sm || breakpoints.md
              ? <hr className={styles.workshopHr}/> : null
            }
            <StaticImage
              className={styles.workshopImage}
              alt="Workshop - ジンバブエ楽器「ンビラ」"
              src="../../assets/images/thumbnail-mbira.jpg"
            />
            <h1 className={styles.workshopName}>ジンバブエ楽器「ンビラ」</h1>
            <hr className={styles.workshopHr}/>
            <p className={styles.workshopText}>
              鉄の棒と木の板から作られたジンバブエの伝統楽器「<strong>ンビラ</strong>」の弾き方を学ぶワークショップを毎月開催しています。
            </p>
            <h2 className={styles.workshopH2}>「ンビラ」とは？</h2>
            <p className={styles.workshopText}>
              アフリカ・ジンバブエに住むショナ族古来の民族楽器。<br/>
              <br/>
              祭礼や儀式の時に先祖の霊やスピリット（精霊）との交信をするために演奏されてきた神聖な役割を持つ楽器で、<strong>オルゴールの原型</strong>となった楽器とも言われています。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」とは？"
                src="../../assets/images/mbira-1.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              鉄の棒をハンマーで叩いて作った平らなキーを、木の板にワイヤーやボルトで締め付けて装着し、全てハンドメイドで作られているので、同じものが一つとしてありません。
            </p>
            <h2 className={styles.workshopH2}>「ンビラ」の音色・演奏について</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」の音色・演奏について"
                src="../../assets/images/mbira-2.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              演奏は2台以上のンビラでのセッションが基本で、1台で奏でる時より、複雑に音が混ざりあい、深みを増します。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」の音色・演奏について"
                src="../../assets/images/mbira-3.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              見た目が強そうな楽器ですが、その音色はオルゴールのように優しく、弾いていても聞いても癒されます。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」の音色・演奏について"
                src="../../assets/images/mbira-4.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              ンビラの特徴のひとつで、板の下部に数個のボトルキャップや鉄のバーが取り付けられており、これによって弾いたときにンビラ独特の響きが加わり、美しいだけの音色にノイズが生まれます。
            </p>
            <h2 className={styles.workshopInstructor}>ワークショップ講師：光田翼さん</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ワークショップ講師：光田翼さん"
                src="../../assets/images/mitsuda-tsubasa.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              光田 翼（「名古屋ンビラ部」部長）<br/>
              <br/>
              名古屋市出身、2009年ンビラに出会い、通販サイトで購入し、独学で演奏を始める。<br/>
              <br/>
              翌年、当時名古屋大学大学院で文化人類学を研究しながらンビラ演奏をしていた松平勇二に出会い、ンビラを教わる。<br/>
              <br/>
              2012年より名古屋を中心に演奏活動を開始。<br/>
              ジンバブエから来日したンビラ奏者とも多数共演。<br/>
              <br/>
              現在は名古屋ンビラ部の部長として、演奏活動、ンビラ教室、ンビラ製作、販売など行っている。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ワークショップ講師：光田翼さん"
                src="../../assets/images/mbira-live.jpg"
              />
            </div>
            <h2 className={styles.workshopH2}>「ンビラ」ワークショップ</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」ワークショップ"
                src="../../assets/images/mbira-5.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              ワークショップはンビラを始めて触る方や楽器が始めての方からご参加いただけます。<br/>
              <br/>
              初めての方も簡単な曲が1曲弾けるようになるので、何か音楽やってみたいなと思っている方にもおすすめです。<br/>
              <br/>
              今までにお子さんや80歳の方も参加していただいており、幅広い年代の方に楽しんでいただいています。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ンビラ」ワークショップ"
                src="../../assets/images/mbira-6.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              Mond営業中に開催していますので、カフェ利用の際に演奏を聞いたり、見学も可能です。
            </p>
            <hr className={styles.workshopHr}/>
            <h2 className={styles.workshopH2}>「ンビラ」ワークショップ詳細</h2>
            <p className={styles.workshopText}>
              ・毎月開催<br/>
              ・所要時間2時間<br/>
              ・料金：1000円（税込）+ ワンドリンク<br/>
              {/* ・日時：<Link to="/#schedule"><strong>スケジュール</strong></Link> */}
              ・日時：LINE・メール・電話でお問い合わせください
            </p>
            <h2 className={styles.workshopH2}>ご予約・お問い合わせ</h2>
            <p className={styles.workshopText}>
              Mond店頭・メール・LINE・電話などで受け付けています。<br/>
              【電話】052-750-5188<br/>
              【メールアドレス】mond14@icloud.com<br/>
              <br/>
              <a className={styles.workshopLineButton} href="https://lin.ee/oekwnCy" target="_blank" rel="noopener noreferrer">
                LINEでかんたん予約
              </a>
            </p>
            <h2 className={styles.workshopH2}>Mond（モーント）</h2>
            <p className={styles.workshopText}>
              名古屋市中区千代田3-6-19<br/>
              地下鉄・JR「鶴舞」駅から徒歩約5分<br/>
              <br/>
              【営業時間】 10〜18時（ラストオーダー17:30）<br/>
              【定休日】 毎週水曜日、第1・第3木曜日
            </p>
          </div>
          <div className={styles.otherWorkshopsContainer}>
            <h1 className={styles.otherWorkshopsTitle}>
              Other Workshop<span className={styles.otherWorkshopsTitleJp}>その他のワークショップ</span>
            </h1>
            <hr className={styles.otherWorkshopsHr}/>
            <div className={styles.otherWorkshopsCardWrapper}>
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <Link to="/workshop/rose-window">
                    <StaticImage
                      className={styles.otherWorkshopsCardImage}
                      alt="ローズウィンドウ"
                      src="../../assets/images/thumbnail-rosewindow.jpg"
                    />
                  </Link>
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>ローズウィンドウ</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    ローズウインドウとは、専用のペーパーを切り抜いて重ねることで、教会のステンドグラス”バ…
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <StaticImage
                    className={styles.otherWorkshopsCardImage}
                    alt="ステンドグラス"
                    src="../../assets/images/thumbnail-stained-glass.jpg"
                  />
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>ステンドグラス</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    準備中…
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <StaticImage
                    className={styles.otherWorkshopsCardImage}
                    alt="真鍮アクセサリー"
                    src="../../assets/images/thumbnail-brass.jpg"
                  />
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>真鍮アクセサリー</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    準備中…
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
            </div>
            {breakpoints.xs || breakpoints.sm || breakpoints.md
              ? <hr className={styles.workshopHr}/> : null
            }
          </div>
        </div>
        {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? null : <hr className={styles.workshopBottomHr}/>
        }
      </div>
    </Layout>
  )
}

export default Mbira
